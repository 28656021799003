export default {
  path: "/purchasing",
  name: "purchasing",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/purchasing/purchase_create",
  children: [
    // {
    //   path: "purchase_create",
    //   name: "purchase_create",
    //   meta: { title: "新增采购单", permission: "purchase_create" },
    //   component: () => import("@/views/purchasing/purchaseCreate/index"),
    // },
    // {
    //   path: "purchase_update",
    //   meta: { title: "编辑采购单", permission: "purchase_update" },
    //   component: () => import("@/views/purchasing/purchaseUpdate/index"),
    // },
    // {
    //   path: "purchase_record",
    //   meta: { title: "采购单", permission: "purchase_record" },
    //   component: () => import("@/views/purchasing/purchaseRecord/index"),
    // },
    // {
    //   path: "purchase_record_detail",
    //   meta: { title: "采购单详情", permission: "purchase_record_detail" },
    //   component: () => import("@/views/purchasing/purchaseRecordDetail/index"),
    // },
    {
      path: "purchase_return_create",
      meta: { title: "新增采购退货单", permission: "purchase_return_create" },
      component: () => import("@/views/purchasing/purchaseReturnCreate/index"),
    },
    {
      path: "purchase_return_update",
      meta: { title: "编辑采购退货单", permission: "purchase_return_update" },
      component: () => import("@/views/purchasing/purchaseReturnUpdate/index"),
    },
    {
      path: "return_record",
      meta: { title: "采购退货", permission: "return_record" },
      component: () => import("@/views/purchasing/returnRecord/index"),
    },
    {
      path: "return_record_detail",
      meta: { title: "采购退货单详情", permission: "return_record_detail" },
      component: () => import("@/views/purchasing/returnRecordDetail/index"),
    },
    {
      path: "purchase_inquiry_order_list",
      meta: { title: "采购询价", permission: "purchase_inquiry_order" },
      component: () => import("@/views/purchasing/purchaseInquiryOrderList/index"),
    },
    {
      path: "purchase_inquiry_order_create",
      meta: { title: "采购询价创建", permission: "purchase_inquiry_order" },
      component: () => import("@/views/purchasing/purchaseInquiryOrderCreate/index"),
    },
    {
      path: "purchase_inquiry_order_update",
      meta: { title: "采购询价编辑", permission: "purchase_inquiry_order" },
      component: () => import("@/views/purchasing/purchaseInquiryOrderUpdate/index"),
    },
    {
      path: "purchase_inquiry_order_detail",
      meta: { title: "采购询价详情", permission: "purchase_inquiry_order" },
      component: () => import("@/views/purchasing/purchaseInquiryOrderDetail/index"),
    },
    {
      path: "purchase_inquiry_task_list",
      meta: { title: "询价记录", permission: "purchase_inquiry_task" },
      component: () => import("@/views/purchasing/purchaseInquiryTaskList/index"),
    },
    {
      path: "purchase_requisition_order_list",
      meta: { title: "采购申请", permission: "purchase_requisition_order" },
      component: () => import("@/views/purchasing/purchaseRequisitionOrderList/index"),
    },
    {
      path: "purchase_requisition_order_create",
      name: "purchase_requisition_order_create",
      meta: { title: "采购申请创建", permission: "purchase_requisition_order" },
      component: () => import("@/views/purchasing/purchaseRequisitionOrderCreate/index"),
    },
    {
      path: "purchase_requisition_order_update",
      meta: { title: "采购申请编辑", permission: "purchase_requisition_order" },
      component: () => import("@/views/purchasing/purchaseRequisitionOrderUpdate/index"),
    },
    {
      path: "purchase_requisition_order_detail",
      meta: { title: "采购申请详情", permission: "purchase_requisition_order" },
      component: () => import("@/views/purchasing/purchaseRequisitionOrderDetail/index"),
    },
    {
      path: "purchase_order_list",
      meta: { title: "采购合同", permission: "purchase_order" },
      component: () => import("@/views/purchasing/purchaseOrderList/index"),
    },
    {
      path: "purchase_order_detail",
      meta: { title: "采购合同详情", permission: "purchase_order" },
      component: () => import("@/views/purchasing/purchaseOrderDetail/index"),
    },
  ],
};
