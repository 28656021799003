export default {
  path: "/sale",
  name: "sale",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/sale/sale_create",
  children: [
    {
      path: "sale_create",
      meta: { title: "新增销售单", permission: "sale_create" },
      component: () => import("@/views/sale/saleCreate/index"),
    },
    {
      path: "sale_update",
      meta: { title: "编辑销售单", permission: "sale_update" },
      component: () => import("@/views/sale/saleUpdate/index"),
    },
    {
      path: "sale_record",
      meta: { title: "销售单", permission: "sale_record" },
      component: () => import("@/views/sale/saleRecord/index"),
    },
    {
      path: "sale_record_detail",
      meta: { title: "销售单详情", permission: "sale_record_detail" },
      component: () => import("@/views/sale/saleRecordDetail/index"),
    },
    {
      path: "sale_return_create",
      meta: { title: "新增销售退货单", permission: "sale_return_create" },
      component: () => import("@/views/sale/saleReturnCreate/index"),
    },
    {
      path: "sale_return_record",
      meta: { title: "销售退货", permission: "sale_return_record" },
      component: () => import("@/views/sale/saleReturnRecord/index"),
    },
    {
      path: "sale_return_detail",
      meta: { title: "销售退货单详情", permission: "sale_return_detail" },
      component: () => import("@/views/sale/saleReturnDetail/index"),
    },

    {
      path: "sales_quotation_order_list",
      meta: { title: "销售报价", permission: "sales_quotation_order" },
      component: () => import("@/views/sale/salesQuotationOrderList/index"),
    },
    {
      path: "sales_quotation_order_create",
      meta: { title: "销售报价创建", permission: "sales_quotation_order" },
      component: () => import("@/views/sale/salesQuotationOrderCreate/index"),
    },
    {
      path: "sales_quotation_order_update",
      meta: { title: "销售报价编辑", permission: "sales_quotation_order" },
      component: () => import("@/views/sale/salesQuotationOrderUpdate/index"),
    },
    {
      path: "sales_quotation_order_detail",
      meta: { title: "销售报价详情", permission: "sales_quotation_order" },
      component: () => import("@/views/sale/salesQuotationOrderDetail/index"),
    },
    {
      path: "sales_order_list",
      meta: { title: "销售合同", permission: "sales_order" },
      component: () => import("@/views/sale/salesOrderList/index"),
    },
    {
      path: "sales_order_detail",
      meta: { title: "销售合同详情", permission: "sales_order" },
      component: () => import("@/views/sale/salesOrderDetail/index"),
    },
    {
      path: "sales_order_update",
      meta: { title: "销售合同编辑", permission: "sales_order" },
      component: () => import("@/views/sale/salesOrderUpdate/index"),
    },
  ],
};
